import {AxiosError} from 'axios'
import qs from 'qs'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../shared/consts'
import {useAxios} from '../../shared/hooks/useApi'
import {PaginationResponse, TaskSource, HProduceTask} from '../types'

const TasksQueryKey = 'tasks'

export type TasksParams = {
  limit: number
  pageNumber: number
  assignedToMe: boolean
  sources?: TaskSource[]
}

export const useTasks = (
  params: TasksParams,
  options?: UseQueryOptions<
    PaginationResponse<HProduceTask>,
    AxiosError,
    PaginationResponse<HProduceTask>
  >
) => {
  const axiosInstance = useAxios()
  const {limit, pageNumber, sources, assignedToMe} = params
  const offset = pageNumber * limit

  return useQuery<PaginationResponse<HProduceTask>, AxiosError, PaginationResponse<HProduceTask>>({
    queryKey: [TasksQueryKey, limit, offset, sources, assignedToMe],
    queryFn: async () => {
      const response = await axiosInstance.get<PaginationResponse<HProduceTask>>(ApiPath.TASKS, {
        params: {
          offset,
          limit,
          sources,
          includeOnlyAssignedToUser: assignedToMe
        },
        paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
      })

      return response.data ?? []
    },
    ...options
  })
}
