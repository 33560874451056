import {Iso8601, User} from '@hconnect/common/types'

export enum TaskSource {
  EASY_POM = 'easyPom',
  COCKPIT = 'cockpit'
}

export enum TaskStatus {
  Pending = 'pending',
  Ongoing = 'ongoing',
  Done = 'done',
  Cancelled = 'cancelled',
  None = 'none',
  WaitingForApproval = 'waitingForApproval',
  Approved = 'approved',
  Rejected = 'rejected',
  WorkOrderCreated = 'workOrderCreated'
}

export type PaginationResponse<T> = {
  items: T[]
  totalItemCount: number
}

export type HProduceTask = {
  status: TaskStatus
  created: Iso8601
  description: string
  dueDate: Iso8601
  externalId: string
  id: string
  plantId: string
  source: TaskSource
  title: string
  assignees: User[]
}

export enum TasksTableSourceFilterValue {
  SHIFT = 'shift',
  POM = 'pom'
}
